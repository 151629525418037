import React from 'react'
import Tabs from './Tabs'
import { walletname } from '../wallet/Wallet'
import { walletimage } from '../wallet/Wallet'

const Keyphase = () => {
  return (
    <div className='container'>
        <div className='flex items-center mb-4'>
            <div>
                <img className='w-8 h-8 mr-1' src={walletimage} alt=''></img>
                </div>
            <div>
                <h3> Import your <span id="walletname">{walletname}</span> wallet</h3>
                </div>
        </div>
        <Tabs/>
       
        <div>
          
        </div>
    </div>
  )
}

export default Keyphase