import React from 'react'
import Keyphase from './Keyphase'
// import './Popup.css'

const Popup = (props) => {
  return (props.trigger) ? (
    <div className='fixed top-0 left-0 w-full h-auto bg-opacity-20 flex justify-center align-middle' id='formTop'>
        <div className='relative p-8 lg:w-2/4 md:w-3/4 bg-white rounded-3xl'>
        {props.childrean}
        <Keyphase/>
        <button className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded float-right transition duration-500 ease' type='button' onClick={() => props.setTrigger(false)}>Cancel</button>
        </div>
    </div>
  ): "";
}

export default Popup