import React from 'react'
import Header from './components/header/Header'
import Main from './components/main/Main'
const App = () => {
  return (
    <div className='px-8 py-10 h-screen'>
    <Header/>
    <Main/>
    </div>
  )
}

export default App