import React from 'react'
import Wallet from '../wallet/Wallet'

const Main = () => {
  return (
    <div className='bg-white max-w-4xl mx-auto mt-12 px-5 py-8 rounded-3xl lg:w-2/4 md:w-3/4'>
      <h2>Connect to a wallet</h2>
      <Wallet/>
    </div>
  )
}

export default Main